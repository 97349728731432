import React, { useEffect } from 'react';
import { split } from 'ramda';
import { navigate } from 'gatsby';
import { postData } from '../utils/fetch';

const Action = ({ location }) => {
  useEffect(() => {
    // note empty string on first member
    const splittedPath = split('/', location.pathname);
    const actionName = splittedPath[2];
    const actionParam1 = splittedPath[3];
    const actionParam2 = splittedPath[4];
    if (actionName === 'gdpr-confirm') {
      postData(`${process.env.FIREBASE_API_URL}/public/gdpr/confirm`, { token: actionParam1 }).then(
        () => {
          navigate('/gdpr-confirmed');
        }
      );
    }
    if (actionName === 'gdpr-registration-confirm') {
      postData(`${process.env.FIREBASE_API_URL}/public/workshop/gdpr/confirm`, {
        workshopKey: actionParam1,
        token: actionParam2,
      }).then(() => {
        navigate('/registration-confirmed');
      });
    }
  }, []);

  return <div>processing</div>;
};

export default Action;
